import React from 'react';
import { Layout, Row, Col, Space } from 'antd';
import BannerImg from '../../static/images/company/workclass-logo.svg';

const { Content, Footer } = Layout;

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Layout style={s.bgWhite}>
      <Content style={s.content}>
        {/* Main Content */}
        <Row justify="center">
          <Col span={22} md={18} xl={16} xxl={10}>
            {children}
          </Col>
        </Row>
      </Content>

      <Footer style={s.footer}>
        <a href="https://workclass.co" target="_blank" rel="noreferrer">
          <Space align="center">
            Made by
            <img src={BannerImg} style={s.img} />
          </Space>
        </a>
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;

const s: Stylesheet = {
  bgWhite: {
    background: 'white'
  },
  content: {
    height: '100%',
    background: 'white',
    flexGrow: 1
  },
  footer: {
    textAlign: 'center',
    background: 'white',
    marginTop: 50
  },
  img: {
    maxHeight: 35
  }
};
